import React from "react";
import { SEO, Layout } from "../components";
import { Container, Wrapper, Column } from "../ui/LayoutComponents";
import { SectionTitle, Text, PageTitle } from "../ui/Texts";
import { colors } from "../ui/variables";
import { ContainedButton } from "../ui/Buttons";

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page non trouvée" />
    <PageTitle color={colors.secondaryBlue}>Page non trouvée</PageTitle>
    <Wrapper>
      <Container>
        <Column>
          <SectionTitle marginB="1rem">Erreur 404</SectionTitle>
          <Text>La page que vous cherchez n'est pas disponible pour l'une des raisons suivantes :</Text>
          <Text>
            <ul>
              <li>Le lien vers cette page est peut-être incorrect ou périmé</li>
              <li>La page a peut-être été déplacée</li>
            </ul>
          </Text>
          <ContainedButton
            color={colors.secondaryBlue}
            hoverColor={colors.primaryBlueHover}
            margin="2rem 0 0 0"
            to="/">
            Retour à l'accueil
          </ContainedButton>
        </Column>
      </Container>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
